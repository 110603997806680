import * as React from "react";
import PropTypes from "prop-types";

import Logo from "./../images/svg/yohan-dev.svg";

const Header = ({ siteTitle }) => (
  <>
    <header className="mx-6 md:mx-8 lg:mx-10 xl:mx-20 py-7 absolute">
      <Logo className="w-32 sm:w-32 md:w-36 lg:w-40 xl:w-44 mx-auto sm:mx-0 fill-current text-white" />
    </header>
  </>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
